import Login from '@/components/login/Login.vue'
import EmailForm from '@/components/email_form/EmailForm.vue'
import Dashboard from '@/components/dashboard/dashboard.vue'
import Unsubscribe from '@/components/subscriber/unsubscribe.vue'
import Subscribe from '@/components/subscriber/subscribe.vue';

export default [
    {
        name: 'Login',
        icon: 'logout',
        path: '/login',
        is_show_on_sidebar: false,
        component: Login
    },
    {
        name: 'Dashboard',
        icon: 'logout',
        path: '/dashboard',
        is_show_on_sidebar: true,
        component: Dashboard
    },
    {
        name: 'Email Blast',
        icon: 'mail',
        path: '/',
        component: EmailForm
    },
    {
      name: 'Unsubscribe',
      icon: 'unsubscribe',
      path: '/subscriber/unsubscribe',
      is_show_on_sidebar: false,
      component: Unsubscribe
  },
  {
    name: 'subscribe',
    icon: 'subscribe',
    path: '/subscriber/subscribe',
    is_show_on_sidebar: false,
    component: Subscribe
  },
];
