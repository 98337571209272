<template>
    <div class="page-layout">
        <a-drawer
        title="Email Receiver"
        placement="right"
        :closable="true"
        :visible="is_show_receiver_form"
        :width="300"
        @close="on_form_close"
        >
            <a-form :form="form">
                <div class="d-flex flex-col">
                    <a-form-item
                        label="Name"
                    >
                        <a-input
                            v-decorator="[
                            'name',
                            { 
                                initialValue: prop_partial_data.name,
                                rules: [
                                    { required: true, message: 'Name is required' }
                                ] 
                            },
                            ]"
                            placeholder="Enter receiver name"
                        />
                    </a-form-item>
                    <a-form-item label="E-mail">
                        <a-input
                            v-decorator="[
                                'email',
                                {
                                    initialValue: prop_partial_data.email,
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid e-mail',
                                        },
                                        {
                                            required: true,
                                            message: 'Email is required',
                                        },
                                    ],
                                },
                            ]"
                            placeholder="Please input receiver email"
                        />
                    </a-form-item>
                    <a-button type="primary" class="mt-8" @click="save">
                        <a-icon type="check-circle" /> Save
                    </a-button>
                </div>
            </a-form>
        </a-drawer>
    </div>
</template>
<script>

export default {
    name: 'ReceiverForm',
    props: [
        'is_show_receiver_form',
        'on_form_close',
        'partial_data',
        'on_save'
    ],
    computed: {
        prop_partial_data(){
            return this.partial_data
        }
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'dynamic_rule' }),
        };
    },
    components: {
    },
    methods: {
        save(){
            this.form.validateFields((err, values) => {
                if (!err) {
                    values.key = this.prop_partial_data.key
                    values.index = this.prop_partial_data.index
                    this.on_save(values)
                    this.form.resetFields()
                }
            });
        }
    },
    mounted(){
    }
}
</script>
<style scoped>

</style>