var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-layout"},[_c('a-drawer',{attrs:{"title":"Email Receiver","placement":"right","closable":true,"visible":_vm.is_show_receiver_form,"width":300},on:{"close":_vm.on_form_close}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"d-flex flex-col"},[_c('a-form-item',{attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'name',
                        { 
                            initialValue: _vm.prop_partial_data.name,
                            rules: [
                                { required: true, message: 'Name is required' }
                            ] 
                        },
                        ]),expression:"[\n                        'name',\n                        { \n                            initialValue: prop_partial_data.name,\n                            rules: [\n                                { required: true, message: 'Name is required' }\n                            ] \n                        },\n                        ]"}],attrs:{"placeholder":"Enter receiver name"}})],1),_c('a-form-item',{attrs:{"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                                initialValue: _vm.prop_partial_data.email,
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid e-mail',
                                    },
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            'email',\n                            {\n                                initialValue: prop_partial_data.email,\n                                rules: [\n                                    {\n                                        type: 'email',\n                                        message: 'The input is not valid e-mail',\n                                    },\n                                    {\n                                        required: true,\n                                        message: 'Email is required',\n                                    },\n                                ],\n                            },\n                        ]"}],attrs:{"placeholder":"Please input receiver email"}})],1),_c('a-button',{staticClass:"mt-8",attrs:{"type":"primary"},on:{"click":_vm.save}},[_c('a-icon',{attrs:{"type":"check-circle"}}),_vm._v(" Save ")],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }