<template>
    <textarea id="summer_note" v-model="value"></textarea>
</template>

<script>
    import "../../../../node_modules/summernote/dist/summernote-lite.min.js"


    export default {
        name: 'CustomSummerNote',
        props: [
            'on_change_content'
        ],
        data() {
            return {
                value: null,
            };
        },
        mounted() {
            global.jQuery = require('jquery');
            var $ = global.jQuery;
            window.$ = $;
            let fontsArray = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto'];
            const vm = this;
            const options = {
            lang: 'en-US',
            height: 400,
            dialogsInBody: true,
            fontNames: fontsArray,
            fontNamesIgnoreCheck: fontsArray,
            fontSizes: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '20', '24', '30', '36', '48' , '64', '82', '150'],
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                ['fontname', ['fontname']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['color', ['color']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview']],
                ['height', ['height']],
                ['fontsize', ['fontsize']]
            ]
            };

            const x = this.on_change_content

            options.callbacks = {
                onChange(contents) {
                    vm.$emit("input", contents);
                    x(contents)
                }
            };

            $('textarea#summer_note').summernote(options);
        },
    };
</script>

<style>
  @import "../../../../node_modules/summernote/dist/summernote-lite.min.css";
  @import '../../../assets/css/_editor.css';
</style>
