<template>
    <div class="page-layout d-flex flex-col m-10">
        <span class="item-title">
                Email Receiver
        </span>
        <div class="d-flex flex-col justify-content-center align-items-center">
            <div class="d-flex flex-col mt-4 mb-4">
                <a-form-item>
                    <a-popover title="Format" trigger="hover">
                    <template slot="content">
                        <p>Name, Email</p>
                    </template>
                        <a-upload
                            v-decorator="[
                            {
                                valuePropName: 'fileList',
                            },
                            ]"
                            name="logo"
                            accept=".csv"
                            :transform-file="transformFile"
                        >
                            <a-button>
                                <a-icon type="upload" /> Upload CSV
                            </a-button>
                        </a-upload>
                    </a-popover>
                </a-form-item>
            </div>
            <p class="line-text w-10"><span>or</span></p>
            <a-button type="primary" @click="addReceiverManually">
                <a-icon type="plus-circle" /> Add manually
            </a-button>
        </div>
        <div class="d-flex flex-col mt-8">
            <a-collapse accordion v-if="getEmailReceivers.length != 0">
                <a-collapse-panel key="1" :header="'Added email receivers ('+getEmailReceivers.length+')'">
                    <a-table
                        :columns="columns"
                        :data-source="getEmailReceivers"
                        :pagination="false"
                        @change="handleTableChange"
                    >
                        <span slot="action" slot-scope="data, record">
                            <a-button type="danger" class="mr-2" @click="deleteReceiver(record.key)">
                                <a-icon type="delete" /> Delete
                            </a-button>
                            <a-button type="dashed" @click="editReceiver(record.key)">
                                <a-icon type="edit" /> Edit
                            </a-button>
                        </span>
                    </a-table>
                </a-collapse-panel>
            </a-collapse>
        </div>
        <div class="d-flex flex-col mt-8">
            <span class="item-title">
                Subject
            </span>
            <a-form-item>
                <a-input
                    v-decorator="['email_subject', { rules: [{ required: true, message: 'Please input email subject' }] }]"
                    placeholder="Enter email subject"
                    v-model="email_subject"
                />
            </a-form-item>
        </div>
        <div class="d-flex flex-col mt-8">
            <span class="item-title">
                Content
            </span>
            <CustomSummerNote :on_change_content="onContentUpdate" />
        </div>
        <a-button type="primary" class="w-100 mt-8" @click="send" :loading="is_send_button_loading">
            <a-icon type="check-circle" /> Send
        </a-button>
        <ReceiverForm
            :is_show_receiver_form="is_show_email_receiver_form"
            :on_form_close="onFormClose"
            :partial_data="data"
            :on_save="onSave"
        />
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex"
import email_form_types from '@/modules/store/email_form/type.js'
import VueTrix from "vue-trix"
import ReceiverForm from '@/components/email_form/sub_components/receiver_form.vue'
import axios from "axios"
import system_settings_types from '@/modules/store/system-settings/types.js'
import CustomSummerNote from '@/components/email_form/sub_components/custom_summer_note.vue'

export default {
    name: 'EmailForm',
    components: {
        ReceiverForm,
        CustomSummerNote
    },
    data() {
        return {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    sorter: true,
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    sorter: true,
                    scopedSlots: { customRender: 'email' },
                },
                {
                    title: 'Action',
                    dataIndex: 'email',
                    key: 'action',
                    width: '20%',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            is_show_email_receiver_form: false,
            data: {
                key: 0,
                index: -1,
                name: null,
                email: null
            },
            is_send_button_loading: false,
        };
    },
    computed: {
        ...mapGetters({
            getFormField: email_form_types.GET_FORM_FIELD_VALUE,
            getEmailReceivers: email_form_types.GET_EMAIL_RECEIVERS,
            getUserDetails: system_settings_types.GET_USER_DETAILS
        }),
        email_subject: {
            get() {
                return this.getFormField.email_subject
            },
            set(newValue) {
                this.setFormField({
                    key: 'email_subject',
                    value: newValue
                })
            }
        },
        email_content: {
            get() {
                return this.getFormField.email_content
            },
            set(newValue) {
                this.setFormField({
                    key: 'email_content',
                    value: newValue
                })
            }
        },
    },
    methods: {
        ...mapMutations({
            setFormField: email_form_types.SET_FORM_FIELD_VALUE,
            setReceiverData: email_form_types.SET_RECEIVER_DATA,
        }),
        onContentUpdate(content){
            this.email_content = content
        },
        async send(){
            this.is_send_button_loading = true
            var receivers = [];
            var receivers_full_details = [];

            const form_receivers = this.getFormField.receivers
            for (let i = 0; i < form_receivers.length; i++) {
                receivers.push(form_receivers[i].email)
                receivers_full_details.push({
                    name: form_receivers[i].name,
                    email: form_receivers[i].email
                })
            }
            
            await axios.post(process.env.VUE_APP_ROOT_API+'email_blast', {
                from: `"${this.getUserDetails.user.displayName}" <${this.getUserDetails.user.emails[0].value}>`,
                receivers,
                subject: this.email_subject,
                email_html_body: this.email_content,
                text: null,
                receivers_full_details
            }).then((response) => {
                if (Object.hasOwn(response.data, 'message')) {
                    this.$message.success(response.data.message);
                }else{
                    this.$message.success('Success!');
                }
            }, (error) => {
                const error_message = error.response.data

                if (Object.hasOwn(error_message, 'message')) {
                    this.$message.error(error_message.message);
                }else{
                    this.$message.error('Encounter an issue while processing your request');
                }

                if(Object.hasOwn(error_message, 'errors')){
                    for (const key in error_message.errors) {
                        if (Object.hasOwn(error_message.errors, key)) {
                            this.$message.error(error_message.errors[key].msg);
                        }
                    }
                }
            });

            this.$store.dispatch(email_form_types.CLEAR_FORM_FIELDS)
            this.is_send_button_loading = false
        },
        handleTableChange(pagination, filters, sorter) {
            this.$store.dispatch(email_form_types.SORT_RECEIVERS, {
                column: sorter.field,
                order: sorter.order
            })
        },
        deleteReceiver(key){
            this.$store.dispatch(email_form_types.DELETE_RECEIVER, key)
        },
        editReceiver(key){
            var index = this.getEmailReceivers.findIndex(function(item, i){
                return item.key === key
            });
            if (index > -1) {
                this.data = this.getEmailReceivers[index]
                this.data.index = index

                this.is_show_email_receiver_form = true
            }
        },
        onFormClose(){
            this.clearFormContent()
            this.is_show_email_receiver_form = false
        },
        clearFormContent(){
            this.data = {
                key: 0,
                index: -1,
                name: null,
                email: null
            }
        },
        onSave(data){
            if(data.index != -1){
                this.setReceiverData({
                    index: data.index,
                    value: data
                })
            }else{
                var index = this.getEmailReceivers.findIndex(function(item, i){
                    return item.email === data.email
                });

                if(index != -1){
                    this.$message.error('Email already added');

                    return
                }

                this.$store.dispatch(email_form_types.ADD_NEW_RECEIVER, data)
            }

            this.onFormClose()
        },
        addReceiverManually(){
            this.clearFormContent()
            this.is_show_email_receiver_form = true
        },
        transformFile(file) {
            const reader = new FileReader()
            reader.onload = () => {
                this.processCSV(reader.result)
            }
            reader.readAsBinaryString(file)
        },
        processCSV(data){
            const rows = data.split('\n');
            var twoDimensionalArray = rows.map(row => row.split(','));

            var clean_data = []
            for (let i = 0; i < twoDimensionalArray.length; i++) {
                var k = twoDimensionalArray[i]

                if(k.length == 2){
                    k[0] = k[0].replace(/^\uFEFF/g, "").replace(/^\u00EF?\u00BB\u00BF/g,"")
                    k[1] = k[1].replace(/(\r\n|\n|\r)/gm,'')
                    clean_data.push(k)
                    this.$store.dispatch(email_form_types.ADD_NEW_RECEIVER, {
                        name: k[0],
                        email: k[1]
                    })
                }
            }
        }
    },
    mounted(){
    }
}
</script>
<style scoped>
    .line-text {
        width: 100%;
        text-align: center; 
        border-bottom: 1px solid #e1e1e1; 
        line-height: 0.1em;
        margin: 10px 0 20px;
        color: #e1e1e1;
    } 

    .line-text span { 
        background:#fff; 
        padding:0 10px; 
    }
</style>